<template>
	<div class="order_wrap px-6">
		<v-container>
			<div class="txt_box">
				<p>
					<span class="impact"
						>일회성 비용 결제가<br />
						되지 않았습니다.
					</span>
					<span class="txt_body1">확인 후 다시 진행해 주세요. </span>
				</p>
				<h3 class="mb-3">
					결제 요청 정보
				</h3>
				<div class="info_list my-3">
					<dl>
						<dt>{{ recp.AF_PAY_MTHD === '11' ? '예금주' : '명의자' }}</dt>
						<dd>{{ recp.AF_OWNER_NM }}</dd>
					</dl>
					<dl>
						<dt>{{ recp.AF_PAY_MTHD === '11' ? '은행' : '카드사' }}</dt>
						<dd>
							{{ recp.AF_BANK_CD_NM }}
						</dd>
					</dl>
					<dl>
						<dt>{{ recp.AF_PAY_MTHD === '11' ? '계좌번호' : '카드번호' }}</dt>
						<dd>{{ recp.AF_CARD_NO }}</dd>
					</dl>
					<dl>
						<dt>결제금액</dt>
						<dd>{{ amount | comma }}원</dd>
					</dl>
					<dl>
						<dt>거부사유</dt>
						<dd>{{ msg }}</dd>
					</dl>
				</div>
			</div>
		</v-container>
		<div class="bottom_box my-3 mt-6">
			<v-btn
				depressed
				color="primary"
				height="48px"
				class="col_1_small"
				@click="receipt"
			>
				결제 재확인
			</v-btn>
		</div>
	</div>
</template>

<script>
/**
 * 일회성 결제 실패시
 * @description 일회성 결제를 실패하였을 경우 보여지는 화면이다.
 * @화면ID : SB-MO-FO-0105
 * @Date : 2022.12.08 확인
 */
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById,
	updateCheckingSchedule
} from '@/apis/order.status.api'
import { bankInstantTransfer, cardTransfer } from '@/apis/order.recp.api'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
import filters from '@/mixins/filters'
import { mapActions } from 'vuex'

export default {
	name: 'CustRecpUnComplete',
	mixins: [filters],
	data() {
		return {
			mobOrderNo: this.$store.getters['verify/mobOrderNo'],
			msg: '',
			amount: '',
			div: null,
			recp: {},
			deliveryInfos: {}
		}
	},
	created() {
		this.initAppPage()
		this.recp = this.$store.getters['recp/payRecp']
	},
	async mounted() {
		const res = await fetchWebOrderInfoById(this.mobOrderNo)
		const webParams = res.resultObject
		const order = JSON.parse(webParams.orderInfoJson)
		this.deliveryInfos = order.deliveryInfos

		const { errMsg, amt, div } = this.$route.params

		if (errMsg) {
			this.msg = errMsg
			this.amount = amt
			this.div = div
		} else {
			let recp = order.recp.recpInfos
			this.msg = recp.errMsg
			this.amount = recp.amount
		}
	},
	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		// 애플리케이션 브릿지
		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		// 애플리케이션 브릿지 함수 : 창닫기
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		},
		async receipt() {
			const recpParams = this.$store.getters['recp/recpParams']
			recpParams.zwebRecp = '07' // 서명 후 이체는 '04' -> '07'
			recpParams.salesApp = this.$store.getters['common/app'] // 앱 구분자 추가
			recpParams.salesType = this.$store.getters['common/type']

			let transferResponse = {}
			if (recpParams.payMthd === '11') {
				// 은행 결제 체크
				await bankInstantTransfer(recpParams)
					.then(res => {
						transferResponse = res
					})
					.catch(error => {
						this.$log(error)
						this.$alert({ message: error })
					})
			} else {
				// 카드 결제 체크
				await cardTransfer(recpParams)
					.then(res => {
						transferResponse = res
					})
					.catch(error => {
						this.$log(error)
						this.$alert({ message: error })
					})
			}

			const { E_RETURN } = transferResponse.resultObject.data
			if (E_RETURN.TYPE === 'S') {
				await this.confirmBooking()

				// 전자서명 이후 모바일주문번호를 사용하기 위해 세션스토리지에 저장
				sessionStorage.setItem('mobOrderNo', this.mobOrderNo)
				setTimeout(() => {
					// 전자서명 완료
					this.$router.push({
						name: 'ec-cust-complete'
					})
				}, 300)
			} else {
				this.$alert({
					message:
						'일회성 비용 결제가 정상적으로 진행되지 않았습니다. 확인 후 다시 진행해 주세요.'
				})

				const res = await fetchWebOrderInfoById(this.mobOrderNo)
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)

				let recp = order.recp.recpInfos
				recp = Object.assign(recp, { errMsg: E_RETURN.MESSAGE })
				this.msg = recp.errMsg

				webParams.orderInfoJson = JSON.stringify(order)
				postWebOrderInfoById(this.mobOrderNo, webParams)
			}
		},
		async confirmBooking() {
			// deliveryInfos 업데이트
			const deliveryParams = { deliveryInfos: [...this.deliveryInfos] }

			const result = await updateCheckingSchedule(
				this.mobOrderNo,
				deliveryParams
			)

			if (
				result.resultObject.statusCode === '109' ||
				result.resultObject.statusCode === '111'
			) {
				this.showSnackbar({
					msg:
						'일회성 비용 결제가 완료되었습니다. 주문 확정 화면이 보일 때까지 화면을 유지해 주세요.',
					color: '#222'
				})
				this.progress = false
			}
		}
	}
}
</script>
